<template>
  <data-list
    ref="dataList"
    class="content lg"
    :url="url"
    entity-name="请假记录"
    :add-btn="null"
    hide-show-detail
    hide-edit
    hide-delete
    row-key="user_id"
    :custom-url="customUrl"
    :width="1168"
    :action-width="80"
    :action="action"
    :columns="columns"
    :default-query="defaultQuery"
    :request-config="{ noTempleFilter: true }"
    :search-key-type="{year: 2, type: 2, name: 2}">
    <template v-slot:query="{form}">
      <a-form-model-item label="统计年份">
        <a-select
          style="width: 180px"
          placeholder="选择年份筛选"
          :options="yearList"
          v-model="form.year"
          @change="handleYearChange($event, form)">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="月份">
        <a-select
          style="width: 180px"
          placeholder="选择月份筛选"
          :options="monthList"
          v-model="form.month"
          allow-clear
          @change="handleMonthChange">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-select
          style="width: 180px"
          placeholder="选择身份筛选"
          :options="staffTypes"
          allow-clear
          v-model="form.type"
          @change="updateList">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-input-search v-model="form.name" placeholder="输入姓名/法名查询" @search="updateList"></a-input-search>
      </a-form-model-item>
    </template>
    <template v-slot:button="{form}">
      <a-dropdown :trigger="['click']">
        <a-button type="primary" :loading="exporting">{{exporting ? '正在导出' : '数据导出'}}</a-button>
        <a-menu slot="overlay">
          <a-menu-item :key="0" @click="exportData(form, 1)">事假/病假</a-menu-item>
          <a-menu-item :key="1" @click="exportData(form, 2)">公假</a-menu-item>
        </a-menu>
      </a-dropdown>
    </template>
    <template v-slot:action="{item}">
      <span class="txt-btn" @click="showDetail(item)">查看</span>
    </template>
  </data-list>
</template>

<script>
    import { staffTypes, getStaffTypeName } from "../../common/hr/book";
    import {downLoadBlobFile, isEmpty} from "../../common/js/tool";

    export default {
        name: "LeaveRecord",
        data() {
            return {
                loading: false,
                url: "/admin/leave/list",
                staffTypes,
                year: 0,
                month: undefined,
                yearList: [],
                monthList: [],
                holidayList: [],
                defaultQuery: {},
                exporting: false,
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
            columns() {
                let res = [
                    { title: '年份', width: 80, customRender: () => this.year + "年" },
                    { title: '月份', width: 80, customRender: () => isEmpty(this.month) ? '--' : (this.month + 1) + "月"},
                    { title: '成员', width: 100, dataIndex: 'name', customRender: (text, record) => text ? text : <open-data  type="userName`" openid={record.user_id}/> },
                    { title: '身份', width: 80, dataIndex: 'type', customRender: getStaffTypeName },
                ];
                const holidayList = this.holidayList;
                const normal = [], sz = [];
                holidayList.forEach(h => {
                   if(h.h_type == 1) {
                       normal.push(h);
                   } else {
                       sz.push(h);
                   }
                });
                if(normal.length > 0) {
                    const ids = normal.map(item => item.id);
                    res.push({ title: '请假总时长（天）', width: 160, customRender: record => this.getTotalCount(record, ids) });
                    normal.forEach(h => {
                        const title = `${h.name}-${getStaffTypeName(h.type)}（天）`;
                        res.push({ title, width: title.length * 14 + 32, customRender: record => (record.count || {})[h.id] || 0 })
                    });
                }
                if(sz.length > 0) {
                    const ids = sz.map(item => item.id);
                    res.push({ title: '僧职告假（次）', width: 140, customRender: record => this.getTotalCount(record, ids) });
                    sz.forEach(h => {
                        const title = `${h.name}（次）`;
                        res.push({ title, width: title.length * 14 + 32, customRender: record => (record.count || {})[h.id] || 0 })
                    });
                }
                return res;
            }
        },
        created() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth();
            const start = 2022; // 最早开始年份
            this.yearList = new Array(year - start + 1).fill(year).map((y, i) => Object({key: y - i, title: y - i + "年"}));
            this.defaultQuery = {year, month};
            this.year = year;
            this.month = month;
            this.setMonthList();
            this.getHolidayList();
        },
        methods: {
            exportData(form, type) {
                if(this.exporting) return;
                this.exporting = true;
                let fileName = type == 1 ? '事假/病假记录' : '公假记录';
                let url = `/admin/leave/export?export_type=${type}${this.customUrl(form)}`;
                this.$axios(url, {
                    noTempleFilter: true,
                    responseType: 'blob',
                }).then(res => {
                    downLoadBlobFile(res, fileName);
                }).finally(() => {
                    this.exporting = false;
                });
            },
            showDetail(record) {
                this.$router.push({name: "PersonLeave", params: {user: record.user_id}})
            },
            setMonthList() {
                const year = this.year;
                const now = new Date();
                const cy = now.getFullYear();
                const cm = now.getMonth();
                const max = year === cy ? cm : 11;
                this.monthList = [
                    { value: '', label: '全年' },
                    ...new Array(max + 1).fill(0).map((item, index) => {
                        return {
                            value: item + index,
                            label: `${item + index + 1}月`
                        }
                    })
                ];
            },
            handleYearChange(val, form) {
                this.year = val;
                this.setMonthList();
                if (!isEmpty(form.month) && this.monthList.findIndex(m => m.value === form.month) === -1) {
                    this.$set(form, 'month', undefined);
                }
                this.updateList();
            },
            handleMonthChange(val) {
                this.month = val;
                this.updateList();
            },
            customUrl(form) {
                let res = "";
                if(form.year) {
                    if (!isEmpty(form.month)) {
                        const date = new Date(form.year, form.month, 1);
                        const start = date.pattern("yyyy-MM-dd") + ' 00:00:00';
                        date.setMonth(date.getMonth() + 1);
                        const end = date.pattern("yyyy-MM-dd") + ' 00:00:00';
                        res += `&start_time=${start}&end_time=${end}`;
                    } else {
                        res += `&start_time=${form.year}-01-01 00:00:00&end_time=${form.year}-12-31 23:59:59`;
                    }
                }
                if(form.type) {
                    res += `&type=${form.type}`;
                }
                if(form.name) {
                    res += `&name=${form.name}`;
                }
                return res;
            },
            getHolidayList() {
                const url = '/admin/holiday?pageSize=1000';
                this.$axios(url).then(res => {
                    this.holidayList = res.data;
                });
            },
            getTotalCount(record, ids) {
                let res = 0;
                const count = record.count;
                if(count) {
                    res = Object.keys(count).reduce((acc, val) => {
                        if(ids.indexOf(val * 1) >= 0) {
                            acc += count[val];
                        }
                        return acc;
                    }, res);
                }
                return res;
            },
            updateList() {
                this.$refs.dataList.getList();
            },
        }
    }
</script>

<style lang="less">
.holiday-over {
  color: @error-color;
}
</style>
